/* You can add global styles to this file, and also import other style files */

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeng/resources/themes/saga-blue/theme.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeflex/primeflex.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import './assets/layout/css/layout.css';
@import 'assets/demo/flags/flags.css';

/* Primeflex 3 items */
// @import '../node_modules/primeflex/primeflex.min.css';
// @import '../node_modules/primeflex/primeflex.css';
// @import '../node_modules/primeflex/primeflex.scss';
// @import '../node_modules/primeflex/themes/saga-blue.css';
// @import '../node_modules/primeflex/themes/saga-blue.scss';

.open-po-search li:nth-child(odd) {
    background-color: lightcyan !important;
}

.open-po-search .top-line {
    width:300px;  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.open-po-search .bottom-line {
    width:300px;  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.open-po-search .bottom-line-1 {
    width:150px;
    float:left;
}

.open-po-search .bottom-line-2 {
    width:150px;
    float:right;
    text-align: right;
}

.line-progress .p-progressbar {
    height: 3px;
}
